import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PageHeader from "../../components/PageHeader"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { InView } from "react-intersection-observer"
import { motion } from "framer-motion"
import {
  animeFadeUp,
  animeSlideOutLeft,
  animeSlideOutBot,
  animeFadeIn
} from "../../utils/Animations"

const ServiceAtelierPage = ({data, transitionStatus}) => {

  return (
    <Layout>
      <SEO
        title="Atelier cocktails - Belladrinks bar à cocktails événementiel"
        description="Apprenez à réaliser des cocktails au contact d’un professionnel. L’atelier cocktails Belladrinks est le cadre idéal pour découvrir le monde de la mixologie et apprendre à utiliser les outils du barman."
      />
      <PageHeader data={data} transitionStatus={transitionStatus} title={"Atelier cocktails"}/>
      <section className="py-16 md:py-40 2xl:py-56 2xl:pb-48">
        <InView triggerOnce={true} threshold={0.5} skip={transitionStatus !== "entered"}>
          {({ inView, ref, entry }) => (
            <motion.div
              animate={inView ? "visible" : "hidden"}
              initial={false}
              variants={{
                visible: {
                  transition: { staggerChildren: 0.4 }
                }
              }}
              className="main-container grid grid-cols-1 xl:grid-cols-2 lg:gap-4" ref={ref}
            >
              <div>
                <motion.h2 variants={animeFadeUp} className="display-1 mb-8 pr-10">Découvrez le monde de la mixologie au contact d'un professionnel.</motion.h2>
                <motion.div
                  variants={{
                    hidden:{
                      width: 0
                    },
                    visible: {
                      width: '7rem',
                      transition: {
                        ease: "easeOut",
                        duration: "0.6"
                      }
                    }
                  }}
                  className="border-b-4 border-copper mt-2 mb-8"
                />
              </div>
              <div>
                <motion.p variants={animeFadeUp} className="lead">
                  Agiter, mélanger, et remuer ne sont qu’un aspect de cette science du cocktail qu’est la mixologie. Vous apprendrez l’histoire des grands cocktails mais aussi à utiliser les
                  outils du bar. L’atelier cocktails Belladrinks est le cadre idéal pour apprendre à réaliser des cocktails au contact d’un professionnel avec vos proches ou dans le cadre d’un teambuilding.
                </motion.p>
              </div>
            </motion.div>
          )}
        </InView>
      </section>
      <section className="main-container">
        <div className="grid grid-cols-2 gap-2 lg:grid-rows-2 lg:grid-flow-row lg:auto-rows-fr">
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <div ref={ref} className="relative h-64 lg:h-auto lg:row-span-2 overflow-hidden">
                <motion.div animate={inView ? "visible" : "hidden"} variants={animeSlideOutLeft} className="absolute inset-0 bg-pampas z-10"/>
                <div className="absolute inset-0">
                  <Img
                    className="h-full"
                    fluid={data.img1.childImageSharp.fluid}
                  />
                </div>
              </div>
            )}
          </InView>
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <div ref={ref} className="relative h-64 lg:h-auto overflow-hidden">
                <motion.div animate={inView ? "visible" : "hidden"} variants={animeSlideOutLeft} className="absolute inset-0 bg-pampas z-10"/>
                <div className="absolute inset-0">
                  <Img
                    className="h-full"
                    fluid={data.img2.childImageSharp.fluid}
                  />
                </div>
              </div>
            )}
          </InView>
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <motion.div
                ref={ref}
                animate={inView ? "visible" : "hidden"}
                initial={false}
                variants={{
                  visible: {
                    transition: { staggerChildren: 0.4 }
                  }
                }}
                className="bg-white py-10 px-8 md:p-16 md:py-20 2xl:p-20 2xl:py-32 col-span-2 lg:col-auto"
              >
                <motion.h2 variants={animeFadeUp} className="display-2 mb-6">L'atelier cocktails</motion.h2>
                <motion.p variants={animeFadeUp} className="lead">
                  Pendant une à deux heures, profitez d'un moment de partage pour apprendre à réaliser des cocktails et découvrir leurs histoires.
                  Nos mixologues se déplacent dans la région lyonnaise pour des groupes de 5 à 40 personnes.
                </motion.p>
              </motion.div>
            )}
          </InView>
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <div ref={ref} className="relative h-64 lg:h-auto overflow-hidden col-span-2 ">
                <motion.div animate={inView ? "visible" : "hidden"} variants={animeSlideOutLeft} className="absolute inset-0 bg-pampas z-10"/>
                <div className="absolute inset-0">
                  <Img
                    className="h-full"
                    fluid={data.img3.childImageSharp.fluid}
                  />
                </div>
              </div>
            )}
          </InView>
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <motion.div
                ref={ref}
                animate={inView ? "visible" : "hidden"}
                initial={false}
                variants={{
                  visible: {
                    transition: { staggerChildren: 0.4 }
                  }
                }}
                className="bg-white py-10 px-8 md:p-16 md:py-20 2xl:p-20 2xl:py-32 col-span-2 lg:col-auto"
              >
                <motion.h2 variants={animeFadeUp} className="display-2 mb-6">Toujours plus engagé</motion.h2>
                <motion.p variants={animeFadeUp} className="lead">
                  Depuis 2016, nous utilisons des pailles écologiques obtenu grâce à de l'amidon de maïs. Nous favorisons les circuits courts ainsi que les produits de saisons et transformons un maximum de matières premières dans nos locaux.
                </motion.p>
              </motion.div>
            )}
          </InView>
          <InView threshold={0.25} triggerOnce={true} skip={transitionStatus !== "entered"}>
            {({ inView, ref, entry }) => (
              <div ref={ref} className="relative h-64 col-span-2 lg:col-auto lg:h-auto overflow-hidden">
                <motion.div animate={inView ? "visible" : "hidden"} variants={animeSlideOutBot} className="absolute inset-0 bg-pampas z-10"/>
                <div className="absolute inset-0">
                  <Img
                    className="h-full"
                    fluid={data.img4.childImageSharp.fluid}
                  />
                </div>
              </div>
            )}
          </InView>
        </div>
      </section>
      <section className="py-20 md:py-36 lg:py-40 2xl:py-52">
        <InView triggerOnce={true} threshold={0.25} skip={transitionStatus !== "entered"}>
          {({ inView, ref, entry }) => (
            <motion.div
              ref={ref}
              animate={inView ? "visible" : "hidden"}
              className="main-container text-center"
              initial={false}
              variants={{
                visible: {
                  transition: { staggerChildren: 0.2 }
                }
              }}
            >
              <motion.h2 variants={animeFadeIn} className="display-1">Vous avez un projet ?</motion.h2>
              <motion.p variants={animeFadeIn} className="lead mb-8">Contactez notre équipe et obtenez un devis rapidement.</motion.p>
              <motion.div variants={animeFadeIn}>
                <TransitionLink
                  className="button bg-copper text-white hover:bg-white hover:text-copper mr-2 md:mr-8"
                  to="/contact/"
                  exit={{ length: 1 }}
                  entry={{ delay: 1 }}
                >
                  Contactez-nous
                </TransitionLink>
              </motion.div>
            </motion.div>
          )}
        </InView>
      </section>
    </Layout>
  )
}

export default ServiceAtelierPage

export const query = graphql`
    query {
        backgroundHeader: file(relativePath: { eq: "atelier.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(relativePath: { eq: "services/atelier/2015_05_20BELLADRINKS-HD-14.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img2: file(relativePath: { eq: "services/atelier/helena-lopes-a4z8GRkVSUM-unsplash.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img3: file(relativePath: { eq: "services/atelier/olena-sergienko-JjGLEN7T8xI-unsplash.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img4: file(relativePath: { eq: "services/atelier/2015_05_20BELLADRINKS-HD-5-2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        backgroundCocktail: file(relativePath: { eq: "cocktails/bunnybunnyboom.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 60) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`