import React from "react"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import { InView } from 'react-intersection-observer';
import { animeFadeUp } from "../../utils/Animations"

const PageHeader = ({data, title, transitionStatus}) => {
  return (
    <section>
      <div className="w-full min-h-1/2 relative flex flex-col justify-center items-center pt-20">
        <div>
          <div className="main-container py-40 text-center">
            <div className={"overflow-hidden"}>
              <InView triggerOnce={true} threshold={0.35} skip={transitionStatus !== "entered"}>
                {({ inView, ref, entry }) => (
                  <motion.h1
                    ref={ref}
                    animate={inView ? "visible" : "hidden"}
                    initial={false}
                    variants={animeFadeUp}
                    className="title-header text-white mb-10">{
                      title}
                  </motion.h1>
                )}
              </InView>

            </div>
          </div>
        </div>
        <div className="absolute inset-0 bg-black -z-1">
          <Img
            className="h-full opacity-70"
            fluid={data.backgroundHeader.childImageSharp.fluid}
          />
        </div>
      </div>
    </section>
  )
}

export default PageHeader
